import { useContext } from "react"

import { ServerContext } from "@/modules/server/context"
import { isFlagEnabled } from "@/modules/unleash/isFlagEnabled"

export function useFlag(flagName: string, variantName?: string): boolean {
  const context = useContext(ServerContext)

  if (!context) {
    return false
  }

  const { activeFlags } = context

  return isFlagEnabled(activeFlags, flagName, variantName)
}

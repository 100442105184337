import type { EnabledFlag } from "@/modules/unleash/types"

export const isFlagEnabled = (activeFlags: EnabledFlag[], flagName: string, variantName?: string): boolean => {
  return !!activeFlags.find(({ name, variant }) => {
    if (!variantName) {
      return name === flagName
    }

    return name === flagName && variant === variantName
  })
}
